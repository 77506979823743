import * as Turbo from "@hotwired/turbo";
import * as Shoelace from "@shoelace-style/shoelace";
import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';
import Headroom from "headroom.js";
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Fancybox } from "@fancyapps/ui";
// import flatpickr from "flatpickr";
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';

function isValidLatLng(lat, lng) {
  if (typeof lat !== 'number' || typeof lng !== 'number') {
    return false; // Ensure that lat and lng are numeric
  }
  
  if (lat < -90 || lat > 90) {
    return false; // Check latitude range
  }

  if (lng < -180 || lng > 180) {
    return false; // Check longitude range
  }

  return true; // If both latitude and longitude are valid
}

// setBasePath('the-luxury-travel-book-assets/node_modules/@shoelace-style/shoelace/dist');

// Register Shoelace Icon Libraries
registerIconLibrary('fa', {
  resolver: name => {
    const filename = name.replace(/^fa[rbs]-/, '');
    let folder = 'regular';
    if (name.substring(0, 4) === 'fas-') folder = 'solid';
    if (name.substring(0, 4) === 'fab-') folder = 'brands';
    return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.1/svgs/${folder}/${filename}.svg`;
  },
  mutator: svg => svg.setAttribute('fill', 'currentColor')
});

registerIconLibrary('lucide', {
  resolver: name => `https://cdn.jsdelivr.net/npm/lucide-static@0.16.29/icons/${name}.svg`
});
// ======

// Register Google Maps with API key
const loader = new Loader({
  apiKey: 'AIzaSyAoH7gIW2_g0oGlFx7zSYbBhD1n9s-qURk',
  version: 'weekly'
});
// ======

// Disable Turbo Drive
Turbo.session.drive = false
// ======



// Destroy Flickity instances when leaving a page
document.addEventListener('turbo:before-cache', function () {
  let carousels = document.querySelectorAll('.carousel');

  carousels.forEach(carousel => {
    const flickityInstance = Flickity.data(carousel);
    if (flickityInstance) {
      flickityInstance.destroy();
    }
  });
});




document.addEventListener("DOMContentLoaded", function(event) {
  // turbo:load

  let maps = {};
  let flickities = [];

  loader.load().then(async () => {
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");

    let arrowShape = { 
      x0: 10,
      x1: 60, y1: 50,
      x2: 65, y2: 45,
      x3: 20
    };

    let mapStyle = [
      {
          "featureType": "administrative",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#444444"
              }
          ]
      },
      {
          "featureType": "administrative.country",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "administrative.province",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "administrative.locality",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "administrative.neighborhood",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
              {
                  "color": "#f2f2f2"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#f2eadf"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
              {
                  "saturation": -100
              },
              {
                  "lightness": 45
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "lightness": "0"
              },
              {
                  "hue": "#ff0000"
              },
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "transit",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
              {
                  "color": "#46bcec"
              },
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "visibility": "on"
              },
              {
                  "color": "#74829f"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#ffffff"
              }
          ]
      }
  ];

  
    // Mini Enquiry: 3, Property Enquiry: 4, General Enquiry: 5, Property Enquiry 2: 8
    // flatpickr("#input_3_3, #input_4_19, #input_5_19, #input_6_26", {
    //   mode: "range",
    //   dateFormat: "F J Y"
    // });




  // Function to update the checkbox labels
  function updateCheckboxLabels() {
    let property_meta = document.querySelector('.property-meta');
    const newLabels = [
      "Within " + property_meta.dataset.region,
      "Anywhere in " + property_meta.dataset.country,
      "Same style of " + property_meta.dataset.type
    ];

    const labels = document.querySelectorAll("#field_8_44 label.gform-field-label--type-inline");

    labels.forEach((label, index) => {
      if (newLabels[index]) {
        label.textContent = newLabels[index];
      }
    });
  }

    jQuery(document).on('gform_page_loaded', function(event, form_id, current_page) {
      console.log('gform_page_loaded');

// 4 = Property Enquiry 1
// 6 = Booking
// 9 = Property Enquiry 2
// 10 = General Enquiry 2
// 11 = Property Enquiry 3
// 13 = Property Enquiry 4
// 14 = General Enquiry 3

      const elements1 = document.querySelectorAll("#input_9_19, #input_10_19, #input_11_19, #input_14_19");
      elements1.forEach(element => {
        new AirDatepicker(element, {
          locale: localeEn,
          range: true,
          multipleDatesSeparator: ' to ',
          dateFormat: "MMMM d yyyy",
          container: element.closest('dialog'),
          autoClose: true,
          onHide: (isFinished) => {
            console.log(isFinished);

            if (!isFinished) return; // Ignore first call
            const dates = element.value.split(' to ');
            const nextButton = document.querySelector('#gform_page_' + form_id + '_' + current_page + ' .gform_next_button');

            let datesFieldId;
            let checkInFieldId;
            let checkOutFieldId;

            if (form_id == 11) { // Property Enquiry 3
              datesFieldId = 19;
              checkInFieldId = 21;
              checkOutFieldId = 20;
            } else if (form_id == 9) { // Property Enquiry 2
              datesFieldId = 19;
              checkInFieldId = 21;
              checkOutFieldId = 20;
            } else if (form_id == 10) { // General Enquiry 2
              datesFieldId = 19;
              checkInFieldId = 21;
              checkOutFieldId = 20;
            } else if (form_id == 6) { // Booking form
              datesFieldId = 26;
              checkInFieldId = 27;
              checkOutFieldId = 28;
            } else if (form_id == 14) { // General Enquiry 3
              datesFieldId = 19;
              checkInFieldId = 21;
              checkOutFieldId = 20;
            }
            

            let datesField = document.querySelector("#input_" + form_id + "_" + datesFieldId);
            console.log(datesField);
            console.log("#input_" + form_id + "_" + datesFieldId);

            if (dates && dates.length === 2) {
              nextButton.disabled = false;

              datesField.closest(".gfield").classList.remove("gfield_error");
              datesField.closest(".gfield").querySelectorAll('.validation_message').forEach(element => element.remove());
            } else {
              nextButton.disabled = true;

              datesField.closest(".gfield").classList.add("gfield_error");
              datesField.closest(".gfield").insertAdjacentHTML("beforeend", '<div class="gfield_description validation_message">Please select a check-out date.</div>');
            }

            const checkInField = document.querySelector('#input_' + form_id + '_' + checkInFieldId);
            const checkOutField = document.querySelector('#input_' + form_id + '_' + checkOutFieldId);

            if (checkInField) {
              checkInField.value = dates[0];
            }

            if (checkOutField) {
              checkOutField.value = dates[1];
            }
          }
        });
      });

      const elements2 = document.querySelectorAll("#input_13_60, #input_13_59"); // 60 is checkin, 59 is checkout
      let checkinDatepicker, checkoutDatepicker;
    
      elements2.forEach(element => {
        const datepicker = new AirDatepicker(element, {
          locale: localeEn,
          dateFormat: "MMMM d yyyy",
          container: element.closest('dialog'),
          autoClose: true,
          onSelect: ({ date }) => {
            if (element.id === "input_13_60") {
              checkinDatepicker = datepicker;
              if (checkoutDatepicker) {
                checkoutDatepicker.update({ minDate: date });
                if (checkoutDatepicker.selectedDates[0] && checkoutDatepicker.selectedDates[0] <= date) {
                  checkoutDatepicker.clear();
                }
              }
            } else if (element.id === "input_13_59") {
              checkoutDatepicker = datepicker;
              if (checkinDatepicker && checkinDatepicker.selectedDates[0] && date <= checkinDatepicker.selectedDates[0]) {
                alert('Checkout date must be later than check-in date');
                checkoutDatepicker.clear();
              }
            }
          }
        });
      });

      jQuery('.increment-field input[type="number"]').each(function () {
        const $numberField = jQuery(this);
        
        // Create increment and decrement buttons
        const $decrementButton = jQuery('<button type="button" class="decrement">-</button>');
        const $incrementButton = jQuery('<button type="button" class="increment">+</button>');
        
        // Add buttons before and after the number field
        $numberField.before($decrementButton);
        $numberField.after($incrementButton);
        
        // Event listener for decrement button
        $decrementButton.on('click', function () {
            let value = parseInt($numberField.val()) || 0;
            $numberField.val(Math.max(0, value - 1)); // Set a minimum of 0
        });
        
        // Event listener for increment button
        $incrementButton.on('click', function () {
            let value = parseInt($numberField.val()) || 0;
            $numberField.val(value + 1);
        });
      });

      // Check if we're on the correct form and the 5th page
      console.log('form_id: ' + form_id + ', current_page: ' + current_page);
      if (form_id == 8 && current_page == 4) {
        updateCheckboxLabels();
      }
    });
  
    // Enable for Booking form
    new AirDatepicker("#input_6_26", {
      locale: localeEn,
      range: true,
      multipleDatesSeparator: ' to ',
      dateFormat: "MMMM d yyyy"
    });

    // let propertyMarkerIcon = {
    //   url: "/wp-content/themes/the-luxury-travel-book/assets/images/compass-marker.png",
    //   scaledSize: new google.maps.Size(40, 40)
    // };
    
    // let plainMarkerIcon = {
    //   url: "/wp-content/themes/the-luxury-travel-book/assets/images/plain-marker.png",
    //   scaledSize: new google.maps.Size(40, 40)
    // };

    // configureMaps();




  let form = document.querySelector('form.filters');
  if (form) {
    // Add event listeners to form fields
    form.querySelectorAll('sl-checkbox, sl-input, sl-select, sl-button').forEach(field => {
      field.addEventListener('sl-change', e => {
        if (document.getElementById('location_id')) {
          e.preventDefault(); // Prevent default form submission behavior

          const formData = new FormData(form);

          const locationValue = formData.getAll('location[]');
          if (locationValue.length === 0) {
            const hiddenInput = document.createElement('input');
            hiddenInput.type = 'hidden';
            hiddenInput.name = 'location[]';
            hiddenInput.value = document.getElementById('location_id').dataset.location_id;

            form.appendChild(hiddenInput);
          }
        }

        if (document.getElementById('inspiration_id')) {
          e.preventDefault(); // Prevent default form submission behavior

          const formData = new FormData(form);

          const inspirationValue = formData.getAll('inspirations[]');
          if (inspirationValue.length === 0) {
            const hiddenInput2 = document.createElement('input');
            hiddenInput2.type = 'hidden';
            hiddenInput2.name = 'inspirations[]';
            hiddenInput2.value = document.getElementById('inspiration_id').dataset.inspiration_id;

            form.appendChild(hiddenInput2);
          }
        }

        // Submit the form
        form.requestSubmit();

        if (document.getElementById('location_id')) {
          form.querySelector('input[type="hidden"][name="location[]"]').remove();
        }
        if (document.getElementById('inspiration_id')) {
          form.querySelector('input[type="hidden"][name="inspirations[]"]').remove();
        }
      });
    });

    // Add event listener to the reset button
    form.querySelector('[type="reset"]').addEventListener('click', e => {
      // Submit the form
      // form.requestSubmit();

      location.reload();
    });
  }






    // Number Inputs
    document.querySelectorAll('.number-spinner').forEach((spinner) => {
      let input = spinner.querySelector('.value');
      let timeout = 1000;

      let canClickDecrement = true;
      spinner.querySelector('.decrement').addEventListener('click', () => {
        input.value--;

        if (input.value < 0) { input.value = 0 }

        if (canClickDecrement) {
          canClickDecrement = false;

          setTimeout(() => {
            input.dispatchEvent(new Event('sl-change')); // Do this as the input gets updated programatically, need to manually trigger the event.
            // form.requestSubmit();
            canClickDecrement = true;
          }, timeout);
        }
      });

      let canClickIncrement = true;
      spinner.querySelector('.increment').addEventListener('click', () => {
        input.value++;

        if (input.value < 0) { input.value = 0 }

        if (canClickIncrement) {
          canClickIncrement = false;

          setTimeout(() => {
            input.dispatchEvent(new Event('sl-change')); // Do this as the input gets updated programatically, need to manually trigger the event.
            // form.requestSubmit();
            canClickIncrement = true;
          }, timeout);
        }
      });
    });
    // ======

    // Infinite Scroll on Properties list

    function do_infinite_scroll() {
      if (document.querySelector('.display-posts-pagination .next')) {
        let propertiesList = document.querySelector('.properties-list');
        if (propertiesList) {

          // Manually tell Infinite Scroll what URL to use as it's smartness was causing it to use wrong URL.
          let next_url = document.querySelector('.display-posts-pagination .next').getAttribute('href');
          next_url = next_url.replace(/dps_paged=\d+/, "dps_paged={{#}}");

          let infScroll = new InfiniteScroll('.properties-list', {
            path: next_url,
            append: '.listed-property',
            button: '.view-more-button',
            history: false,
            scrollThreshold: false,
            checkLastPage: '.display-posts-pagination .next',
          });

          infScroll.on( 'append', function( body, path, items, response ) {
            listed_property_flickities();
          });
        }
      } else {
        if (document.querySelector('.view-more-button')) {
          document.querySelector('.view-more-button').style.display = 'none';
        }
      }
    }

    do_infinite_scroll();

    document.addEventListener('turbo:frame-render', function() {
      if (maps.searchPanelMap) {
        maps.searchPanelMap.markers = [];
        maps.searchPanelMap.latlngbounds = new google.maps.LatLngBounds(); 
      }

      do_infinite_scroll();

      configureMaps();

      listed_property_flickities(); 
    });
    // ======

    // Fancybox
    Fancybox.bind("[data-fancybox]");
    // ======

    // Turbo stuff
    const searchInput = document.querySelector('#search-input');
    const resultsFrame = document.querySelector('#results-frame');

    searchInput.addEventListener('input', async function() {
      if (this.value.length >= 3) {
        const query = this.value;

        // Fetch search results and update the Turbo frame
        const response = await fetchResults(query);
        resultsFrame.innerHTML = response;
      }
    });

    async function fetchResults(query) {
        const formData = new FormData();
        formData.append('action', 'custom_search');
        formData.append('query', query);

        const response = await fetch(customSearch.ajaxurl, {
            method: 'POST',
            body: formData
        });

        return await response.text();
    }
    // ======

    // Ensures dates are in the future in enquiry form
    // if (typeof gform !== 'undefined') {
    //   gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
    //       if ( formId == 4 && fieldId == 19 ) {
    //           optionsObj.minDate = 0;
    //           optionsObj.onClose = function (dateText, inst) {
    //               jQuery('#input_4_19').datepicker('option', 'minDate', dateText).datepicker('setDate', dateText);
    //           };
    //       }
    //       return optionsObj;
    //   });
    // }
    // ======

    // Sets Headroom.js on header
    var myElement = document.querySelector(".site-header");
    var headroom  = new Headroom(myElement);
    headroom.init();
    // ======

    let bannerSlideshowFlickity = false;

    // Configures Flickity on home page banner
    if (document.querySelector('.banner .ss')) {
      var bannerSlideshowElement = document.querySelector('.banner .ss');
      bannerSlideshowFlickity = new Flickity(bannerSlideshowElement, {
        cellSelector: '.photo-wrapper',
        cellAlign: "center",
        contain: true,
        wrapAround: true,
        imagesLoaded: false,
        pageDots: false,
        prevNextButtons: false,
        autoPlay: 5000,
        arrowShape: arrowShape
      });

      bannerSlideshowFlickity.resize();
    }
    // ======

    // Configures Flickity on carousel locations
    let carousels = document.querySelectorAll('.carousel');

    if (carousels.length > 0) {
      carousels.forEach(carousel => {
        var carouselSlideshowElement = carousel;
        new Flickity(carouselSlideshowElement, {
          cellSelector: '.carousel__item',
          cellAlign: "center",
          // contain: true,
          wrapAround: true,
          imagesLoaded: true,
          pageDots: false,
          adaptiveHeight: true,
          // prevNextButtons: true,
          arrowShape: arrowShape
        });
      });
    }
    // ======

    // Configures Flickity on side by side banner
    let sbsSlideshowFlickity = false;

    if (document.querySelector('.slideshow')) {
      var sbsSlideshowElement = document.querySelector('.slideshow');
      let sbsSlideshowFlickity = new Flickity(sbsSlideshowElement, {
        cellAlign: "center",
        contain: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: true,
        pageDots: false,
        prevNextButtons: true,
        arrowShape: arrowShape
      });

      setTimeout(function() {
        sbsSlideshowFlickity.resize();
      }, 1000);
    }
    // ======

    // Configures Flickity on listed-properties
    function listed_property_flickities() {
      if (document.querySelector('.listed-property .property-photos')) {
        document.querySelectorAll('.listed-property .property-photos').forEach(function(photos) {
          let flickity = new Flickity(photos, {
            cellAlign: "center",
            contain: true,
            wrapAround: true,
            imagesLoaded: true,
            pageDots: false,
            lazyLoad: true,
            // prevNextButtons: false
            arrowShape: arrowShape
          });

          flickities.push(flickity);
        });
      }
    }

    listed_property_flickities();

    function resize_flickities() {
      flickities.forEach(flickity => {
        flickity.resize();
      });
    }
    // ======

    // Configures Flickity on why us bar
    // if (document.querySelector('.why-us-slider')) {
    //   var whyusSlideshowElement = document.querySelector('.why-us-slider');
    //   var whyusSlideshowFlickity = new Flickity(whyusSlideshowElement, {
    //     cellAlign: "center",
    //     contain: true,
    //     wrapAround: true,
    //     imagesLoaded: true,
    //     pageDots: false,
    //     prevNextButtons: true,
    //     arrowShape: arrowShape
    //   });
    // }
    // ======

    // Configures Flickity on properties
    if (document.querySelector('.property-slider')) {
      var propertySliderSlideshowElement = document.querySelector('.property-slider');
      var propertySliderSlideshowFlickity = new Flickity(propertySliderSlideshowElement, {
        cellAlign: "center",
        contain: true,
        wrapAround: true,
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: true,
        arrowShape: arrowShape
      });
    }
    // ======

    // Overlay boxes
    let overlayActive = false;
    let favDivActive = false;
    let discoverdivActive = false;
    let navDivActive = false;

    const menuToggles = document.querySelectorAll('.menu-toggle');
    const mainNavigation = document.querySelector('.main-navigation');

    const fav_button = document.querySelectorAll('.fav-button');
    const fav_div = document.querySelector('.fav-div');

    const discover_button = document.querySelectorAll('.discover-button');
    const discover_div = document.querySelector('.discover-div');

    const overlay = document.querySelector('.overlay');

    function closeAllBoxes() {
      if (favDivActive) {
        fav_div.classList.remove('visible');
        favDivActive = false;
      }
      if (discoverdivActive) {
        discover_div.classList.remove('visible');
        discoverdivActive = false;
      }
      if (navDivActive) {
        mainNavigation.classList.remove('visible');
        navDivActive = false;
      }
      if (overlayActive) {
        overlay.classList.remove('visible');
        overlayActive = false;
      }
    }

    function toggleOverlay() {
      if (overlayActive) {
        overlay.classList.remove('visible');
        document.body.classList.remove('panel-open');
        overlayActive = false;
      } else {
        overlay.classList.add('visible');
        overlayActive = true;
      }
    }

    function toggleNavigation() {
      if (navDivActive) {
        mainNavigation.classList.remove('visible');
        document.body.classList.remove('panel-open');
        document.body.classList.remove('navigation-open');
        navDivActive = false;
        document.body.style.overflow = 'scroll';
      } else {
        closeAllBoxes();
        mainNavigation.classList.add('visible');
        navDivActive = true;
        document.body.classList.add('panel-open');
        document.body.classList.add('navigation-open');
        document.body.style.overflow = 'hidden';
      }
      toggleOverlay();
    }

    function toggleFavDiv() {
      if (favDivActive) {
        fav_div.classList.remove('visible');
        document.body.classList.remove('panel-open');
        favDivActive = false;
      } else {
        closeAllBoxes();
        var fav_ids = false;
        var fav_ids_item = localStorage.getItem('luxury-travel-book-favourite-properties');

        var hiddenForm = document.querySelector('#hidden-form form');
        if (fav_ids_item != false) {
          var fav_ids = JSON.parse(fav_ids_item);
          if (fav_ids && fav_ids.length > 0) {
            hiddenForm.querySelector('input[type="hidden"]').value = fav_ids.join(',');
            hiddenForm.querySelector('input[type="submit"]').click();
          }
        } else {
          fav_ids = false;
          hiddenForm.querySelector('input[type="hidden"]').value = [];
          hiddenForm.querySelector('input[type="submit"]').click();
        }

        if (fav_ids) {
          fav_div.classList.remove('no-favs');
        } else {
          fav_div.classList.add('no-favs');
        }

        fav_div.classList.add('visible');
        favDivActive = true;
        document.body.classList.add('panel-open');

        document.addEventListener('turbo:frame-render', () => {
          if (document.querySelector('.clear-fav-properties')) {
            document.querySelector('.clear-fav-properties').addEventListener('click', function(e) {
              localStorage.setItem('luxury-travel-book-favourite-properties', []);
              activateFavouriteButtonsOnFavouritedProperties();
              checkIfFavButtonShouldBeHighlighted();
              closeAllBoxes();
            });
          }
        });
      }
    }

    function toggleDiscoverDiv() {
      if (discoverdivActive) {
        discover_div.classList.remove('visible');
        discoverdivActive = false;
      } else {
        closeAllBoxes();
        discover_div.classList.add('visible');
        discoverdivActive = true;
        document.body.classList.add('panel-open');
        discover_div.querySelector('sl-input').focus();
      }
      toggleOverlay();
    }

    document.addEventListener('keydown', function(event) {
      if (event.key === 'Escape') {
        toggleOverlay();
        closeAllBoxes();
      }
    });

    overlay.addEventListener('click', function(event) {
      closeAllBoxes();
    });
    // ======

    // loop through each menu toggle button and add a click event listener
    menuToggles.forEach(toggle => {
      toggle.addEventListener('click', () => {
          toggleNavigation();
      });
    });
    // ======

    // Favourites box
    fav_button.forEach(element => {
      element.addEventListener('click', function() {
        toggleFavDiv();
      });
    });

    // ======

    // Discover box
    discover_button.forEach(element => {
      element.addEventListener('click', function() {
        toggleDiscoverDiv();
      });
    });
    // ======

    function checkIfFavButtonShouldBeHighlighted() {
      let fav_buttons = document.querySelectorAll('.fav-button');

      if (localStorage.getItem('luxury-travel-book-favourite-properties')) {
        // Key exists, now check if it's not an empty array
        const storedData = JSON.parse(localStorage.getItem('luxury-travel-book-favourite-properties'));

        if (Array.isArray(storedData) && storedData.length > 0) {
          // The key exists, and it contains a non-empty array
          fav_buttons.forEach((button) => {
            button.classList.add('highlight');
          });
        } else {
          // The key exists, but it's either not an array or it's an empty array
          fav_buttons.forEach((button) => {
            button.classList.remove('highlight');
          });
        }
      } else {
        // The key does not exist in local storage
        fav_buttons.forEach((button) => {
          button.classList.remove('highlight');
        });
      }
    }
  
    checkIfFavButtonShouldBeHighlighted();

    function activateFavouriteButtonsOnFavouritedProperties() {
      let favourite_buttons = document.querySelectorAll('.js-add-to-favourites');

      favourite_buttons.forEach(button => {
        let property_id = button.dataset.propertyId;
        if (checkIfPropertyIsFavourited(property_id)) {
          toggleHeart(button, true);
        } else {
          toggleHeart(button, false);
        }
      });

      checkIfFavButtonShouldBeHighlighted();
    }

    activateFavouriteButtonsOnFavouritedProperties();

    // Deals with when any favourite buttons are clicked
    const parentElement = document.body;

    parentElement.addEventListener('click', function(event) {
      const clickedElement = event.target;

      // Check if the clicked element is a button with the class .add-to-favourites
      if (clickedElement.classList.contains('js-add-to-favourites')) {
        let property_id = clickedElement.dataset.propertyId;
        toggleHeart(clickedElement, addOrRemoveFavouriteProperty(property_id));
        checkIfFavButtonShouldBeHighlighted();
      }
    });
    // ======



  // Deals with new favourite buttons added after page is loader
  // Callback function for the MutationObserver
  const mutationCallback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
          for (const addedNode of mutation.addedNodes) {
              if (addedNode.nodeType === Node.ELEMENT_NODE && addedNode.classList.contains('add-to-favourites')) {
                  activateFavouriteButtonsOnFavouritedProperties();
              }
          }
      }
  };

  // Create a new MutationObserver
  const observer = new MutationObserver(mutationCallback);

  // Configure and start observing the document (or a specific container)
  const config = { childList: true, subtree: true };
  observer.observe(document, config);

  // ======

  // Function to toggle active class on heart buttons
  function toggleHeart(heart_element, result) {
    if (result) {
      heart_element.classList.add('active');
    } else {
      heart_element.classList.remove('active');
    }
  }

  // Function to update the local storage of favourited properties
  function addOrRemoveFavouriteProperty(property_id) {
    let result;
    // Get the existing value of the “luxury-travel-book-favourite-properties” key from local storage
    let existingValues = localStorage.getItem('luxury-travel-book-favourite-properties');

    // If the key doesn’t exist yet, create an empty array to store values in
    if (!existingValues) {
      existingValues = [];
    } else {
      // If the key already exists, parse the JSON data to an array
      existingValues = JSON.parse(existingValues);
    }

    // Check if the current post ID is already in the saved values array
    if (existingValues.includes(property_id)) {
      // If it is, remove it from the array
      const index = existingValues.indexOf(property_id);
      if (index !== -1) {
        existingValues.splice(index, 1);
      }

      result = false;
    } else {
      // If it's not in the array, add it
      existingValues.push(property_id);

      result = true;
    }

    // Save the updated array back to local storage
    localStorage.setItem('luxury-travel-book-favourite-properties', JSON.stringify(existingValues));

    return result;
  }

  function checkIfPropertyIsFavourited(property_id) {
    let existingValues = localStorage.getItem('luxury-travel-book-favourite-properties');
    if (existingValues) {
      existingValues = JSON.parse(existingValues);
      if (existingValues.includes(property_id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }





    // Header Height stuff
    const header = document.querySelector('header.site-header');
    const root = document.documentElement;

    function setHeaderHeight() {
      const headerHeight = header.offsetHeight;
      root.style.setProperty('--header-height', `${headerHeight}px`);
      if (bannerSlideshowFlickity) {
        bannerSlideshowFlickity.resize();
        bannerSlideshowFlickity.reposition();
      }
      if (sbsSlideshowFlickity) {
        sbsSlideshowFlickity.reposition();
        sbsSlideshowFlickity.resize();
      }
    }

    setHeaderHeight();

    window.addEventListener('resize', setHeaderHeight);
    // ======

    // Share buttons
    const shareButton = document.querySelector('.share-button');

    if (shareButton) {
      if (navigator.share) {
        shareButton.addEventListener('click', () => {
          navigator.share({
            url: window.location.href
          })
          .catch((error) => {
            console.error('Error sharing:', error);
          });
        });
      } else {
        shareButton.style.display = 'none';
      }
    }
    // ======

    // Toggle property views
      const button = document.querySelector('sl-button.toggle-view');
      const filtersAndResults = document.querySelector('.filters-and-results');

      if (button) {
        button.addEventListener('click', function() {
            switch (button.textContent) {
                // case 'Multi':
                //     // Switching to "Multi"
                //     button.textContent = 'Grid';
                //     filtersAndResults.classList.remove('grid', 'map');
                //     filtersAndResults.classList.add('multi');
                //     break;
                case 'Map':
                    // Switching to "Map"
                    // button.textContent = 'Multi';
                    // filtersAndResults.classList.remove('grid', 'multi');
                    // filtersAndResults.classList.add('map');
                    // break;
                    button.textContent = 'Grid';
                    filtersAndResults.classList.remove('grid', 'map');
                    filtersAndResults.classList.add('map');
                    break;
                case 'Grid':
                    // Switching to "Grid"
                    button.textContent = 'Map';
                    filtersAndResults.classList.remove('map', 'multi');
                    filtersAndResults.classList.add('grid');
                    listed_property_flickities();
                    resize_flickities();
                    break;
                default:
                    console.error('Unexpected button text:', button.textContent);
            }
        });
      }

      // Map
      function do_map(map_element, name) {
        let map = new Map(map_element, {
          zoom: 8,
          center: {lat: 50.069922, lng: -1.083932},
          styles: mapStyle
        });

        // Ensure that maps[name] is an object
        if (!maps[name]) {
          maps[name] = {};
        }

        // Ensure that maps[name].markers is an array
        if (!maps[name].markers) {
          maps[name].markers = [];
        }

        // Ensure that maps[name] is an object
        if (!maps[name].latlngbounds) {
          maps[name].latlngbounds = new google.maps.LatLngBounds();
        }

        maps[name].map = map;
        
        let locations = JSON.parse(map_element.dataset.markers);

        if (locations) {
          iterateLocations(locations, maps[name]);
        }
      }


      function iterateLocations(locations, maps_object) {
        let openInfowindow = null;

        for (var i = 0; i < locations.length; i++) {
          if (isValidLatLng(locations[i].lat, locations[i].lng)) {
            let marker = new google.maps.Marker({
              position: {lat: locations[i].lat, lng: locations[i].lng},
              map: maps_object.map,
              icon: {
                url: "/wp-content/themes/the-luxury-travel-book/assets/images/marker-compass.svg",
                scaledSize: new google.maps.Size(50, 50)
              },
              title: locations[i].title
            });

            const infowindow = new google.maps.InfoWindow();

            maps_object.markers.push(marker);

            (function (location, marker, infowindow) {
              google.maps.event.addListener(marker, 'click', function () {
                if (openInfowindow) {
                  openInfowindow.close();
                }

                fetchPropertyData(location, maps_object.map, marker, infowindow);
                openInfowindow = infowindow;
              });
            })(locations[i], marker, infowindow);

            google.maps.event.addListener(maps_object.map, 'click', function() {
              if (infowindow) {
                infowindow.close();
              }
            });

            maps_object.latlngbounds.extend({lat: locations[i].lat, lng: locations[i].lng});
          }
        }

        // Fit Bounds or center to sole marker
        if ( maps_object.markers.length > 1) {
          maps_object.map.fitBounds(maps_object.latlngbounds);
        } else {
          maps_object.map.setCenter(maps_object.markers[0].getPosition());
          maps_object.map.setZoom(12);
        }

        // Add Marker clustering
        const renderer = {
          render: ({ count, position }) =>
            new google.maps.Marker({
              label: { text: String(count), color: "white", fontSize: "25px", fontFamily: "Domaine" },
              position,
              icon: {
                url: "/wp-content/themes/the-luxury-travel-book/assets/images/marker-plain.svg",
                scaledSize: new google.maps.Size(50, 50)
              },
              // adjust zIndex to be above other markers
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            }),
        };

        new MarkerClusterer({
          map: maps_object.map,
          markers: maps_object.markers,
          renderer: renderer
        });
      };

    function fetchPropertyData(location, map, marker, infowindow) {
        fetch('/wp-json/cbj/v1/property/' + location.id)
            .then(response => response.json())
            .then(data => {
                location.propertyData = data;
                updateInfoWindowContent(location, infowindow);
                infowindow.open({
                    anchor: marker,
                    map
                });
            })
            .catch(error => console.error('Error fetching property data:', error));
    }


      function updateInfoWindowContent(location, infowindow) {
          const contentString =
          '<div class="info-window-content">' +
          '<img src="' + location.propertyData.image + '" alt="' + location.propertyData.title + '" />' +
          '<div>' +
          '<p class="property-name"><a href="' + location.propertyData.url + '" target="_blank">' + location.propertyData.title + '</a></p>' +
          location.propertyData.locations_string +
          location.propertyData.room_string +
          '</div>' +
          "</div>";

          infowindow.setContent(contentString);
      }



      function configureMaps() {
        const map_element_selectors = {
          locationMap: '.location-map',
          propertyMap: '.property-map',
          searchPanelMap: '.search-panel-map',
        };

        Object.entries(map_element_selectors).forEach(([name, className]) => {
          const element = document.querySelector(className);

          if (element) {
              do_map(element, name);
          }
        });
      }

      configureMaps();
      // ======
  });

  // Enable labels of tree items to expand/collapse
  document.querySelectorAll('sl-tree').forEach(tree => {
    tree.addEventListener('click', event => {
      const item = event.target.closest('sl-tree-item');
      // const isExpandButton = event.composedPath().some(el => el.part?.contains('expand-button'));
      const isExpandButton = event.composedPath().some(el => el.part && el.part.contains('expand-button'));

      if (item && !isExpandButton) {
        item.expanded = !item.expanded;
      } 
    });
  });


  // Get the button and filters div
  var toggleButton = document.querySelector('.toggle-filters');
  var filtersDiv = document.querySelector('.the-filters');

  // Add a click event listener to the button
  if (toggleButton) {
    toggleButton.addEventListener('click', function() {
      // Toggle the "visible" class on the filters div
      filtersDiv.classList.toggle('visible');

      // Check if the "visible" class is now present on the filters div
      var filtersVisible = filtersDiv.classList.contains('visible');

      // Update the button text based on the visibility state
      toggleButton.textContent = filtersVisible ? 'Close Filters' : 'Filters';
    });
  }



  // Country Code move cursor selection to start of input
  let countryCodeField = document.querySelector('#input_5_31');
  if (countryCodeField) {
    countryCodeField.addEventListener('mouseup', function (e) {
      var target = e.target;

      if (target.value.replace(/\(|\)|_|-|\./g, '').trim() === '') {
        target.setSelectionRange(0, 0);
      }
    }, false);
  }



  function setupModal(buttonSelector, formSelector, closeSelector, afterOpenCallback, callbackArg) {
    const button = document.querySelector(buttonSelector);
    const form = document.querySelector(formSelector);
    const closeButton = document.querySelector(closeSelector);
  
    if (button && form && closeButton) {
      button.addEventListener('click', () => {
        form.showModal();

        if (typeof afterOpenCallback === 'function') {
          afterOpenCallback(callbackArg);
        }
      });
      closeButton.addEventListener('click', () => form.close());
    }
  }

  function renderForm(id) {
    console.log('Render Form');
    jQuery(document).trigger( 'gform_post_render', [id, 1] );
  }
  
  // Initialize the modals
  setupModal('#property-enquiry-box-button', '#property-enquiry-box-form', '#property-enquiry-box-form-close', renderForm, 9);
  setupModal('#property-enquiry-3-box-button', '#property-enquiry-3-box-form', '#property-enquiry-box-form-close', renderForm, 11);
  setupModal('#general-enquiry-box-button', '#general-enquiry-box-form', '#general-enquiry-box-form-close', renderForm, 14);
});
